<template>
  <v-card class="nested-data__wrapper">
    <VCardTitle class="py-2">
      <v-row justify="space-between">
        <v-col cols="12" md="6">
          <v-app-bar-nav-icon @click.stop="treeSideBar = !treeSideBar"></v-app-bar-nav-icon>
          {{$t('nestedData.nested_data')}}
        </v-col>
        <v-col cols="12" md="3" v-if="permissions.can_choose_deport">
          <VAutocomplete
            dense
            clearable
            :items="Object.values(depots)"
            :item-value="'uuid'"
            :item-text="'name'"
            solo
            :label="$t('form.storage')"
            prepend-inner-icon="mdi-warehouse"
            hide-details
            v-model="chosen_deport"
            :menu-props="{ bottom: true, offsetY: true }"
            @change="onChooseDeport"
            @keypress="validationForTitle($event)"
            @paste="validationForTitle($event)"
          />
        </v-col>
      </v-row>
    </VCardTitle>
    <VDivider />
    <v-row no-gutters>
        <v-col :style="$vuetify.breakpoint.smAndDown ? '' : 'width:0px!important'">
          <v-navigation-drawer
            v-model="treeSideBar"
            class="pa-1 w-100"
            hide-overlay
            style="top: 60px;z-index: 1"
            :absolute="$vuetify.breakpoint.smAndDown"
          >
            <div class="nested_data__aside">
              <v-text-field
                dense
                :placeholder="$t('form.search')"
                color="grey lighten-1"
                prepend-outer-icon="mdi-magnify"
                class=" br-0 search-field"
                filled
                hide-details="auto"
                v-model="searchQuery"
                flat
                clearable
                clear-icon="mdi-close-circle"
                @keypress="validationForTitle($event)"
                @paste="validationForTitle($event)"
              />
              <nested-tree
                :items="items"
                :searchQuery="searchQuery"
                :cells="cells"
                :sectors="sectors"
                :deports="depots"
              />
            </div>
          </v-navigation-drawer>
        </v-col>
      <v-col class="nested_data__aside" :cols="treeSideBar && !$vuetify.breakpoint.smAndDown? 9 : 12">
        <NestedView :chosen-elements="chosenElems" :cells="allCells" :sectors="allSectors" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import NestedTree from "./NestedTree.vue";
import NestedView from "./NestedView.vue";
import user from "../../mixins/user";
import loader from "../../mixins/loader";
import {uniqueByUUID, validationForTitle} from "../../mixins/helpers";
import EventBus from "../../events/EventBus";
import notifications from "../../mixins/notifications";
import depotService from "../../services/request/depot/depotService";
import {mapActions, mapGetters} from "vuex";
import * as actionTypes from "@/store/modules/pageLoadTime/types/actions";
import * as getterTypes from "@/store/modules/pageLoadTime/types/getters";

const defaultUUID = "00000000-0000-0000-0000-000000000000";
export default {
  name: "NestedComponent",
  data: () => ({
    chosen_deport: "",
    depots: [],
    sectors: [],
    cells: [],
    items: [],
    chosenElems: "",
    allSectors: [],
    allCells: [],
    searchQuery: "",
    treeSideBar: true,
    loadTime: 0
  }),
  async mounted() {
    EventBus.$on("choose-elem", data => {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.treeSideBar = false;
      }
      this.chosenElems = data[0];
    });
    EventBus.$on("tree-updated", () => {
      this.onInit();
      this.chosen_deport = "";
    });
    await this.onInit();
    this.checkLoadTime();
  },
  methods: {
    validationForTitle,
    ...mapActions('pageLoadTime', {
      setPageLoadTime: actionTypes.SET_PAGE_LOAD_TIME,
    }),
    checkLoadTime() {
      const endTime = performance.now();
      this.loadTime = endTime - this.startTime;
      this.setPageLoadTime(this.loadTime)
    },
    async onInit() {
      this.setLoading(true);
      if (this.permissions.can_choose_deport) {
        this.depots = await depotService.getDepotsList();
      }
      await this.getSectorsList();
      await this.getCellsList();
      this.allSectors = [...this.sectors];
      this.allCells = [...this.cells];
      this.setTreeView();
      this.setLoading(false);
    },
    getData(arrItem, type = "sector", isgetChildren = true) {
      const children = [];
      if (arrItem === null) return [];
      this.findInsertedCell(arrItem, this.cells).forEach(insertedCell => {
        children.push(this.getData(insertedCell, "cell", true));
      });
      return {
        id: arrItem.uuid,
        name: arrItem.name,
        icon: type === "sector" ? "view-dashboard" : "table-merge-cells",
        type: type,
        id_parent: type === "sector" ? null : arrItem.uuid_sector,
        children: isgetChildren ? children : []
      };
    },
    findInsertedCell(cell, findedArr) {
      return findedArr.filter(findedArrItem => cell.uuid === findedArrItem.uuid_parent) || null;
    },
    setTreeView() {
      let filteredCells = [...this.cells];
      const cellWithChildrens = [];
      const sectorWithChildrens = [];

      filteredCells.forEach(cell => {
        filteredCells = filteredCells.filter(mainCell => mainCell.uuid !== cell.uuid);
        if (cell.uuid_parent === defaultUUID) {
          cellWithChildrens.push(this.getData(cell, "cell", true));
        }
      });

      this.sectors.forEach(sector => {
        const sectorParams = this.getData(sector, "sector", false);
        cellWithChildrens.forEach(cell => {
          if (cell.id_parent === sector.uuid) {
            sectorParams.children.push(cell);
          }
        });
        sectorWithChildrens.push(sectorParams);
      });
      this.items = sectorWithChildrens;

    },
    async onChooseDeport() {
      this.chosenElems = "";
      this.sectors = this.getSecotorsByDeport;
      this.items = [];
      this.setTreeView();
    },
    async getSectorsList(uuidDeport) {
      try {
        const params = {
          order: "DESC",
          orderby: "time_created"
        };
        if (uuidDeport) {
          params.uuid_deport = uuidDeport;
        }
        const newItems = await depotService.getSectorsList(params);
        this.sectors = newItems;
        this.sectors = uniqueByUUID(this.sectors);
      } catch (e) {
        console.log(e)
      }
    },
    async getCellsList() {
      try {
        this.cells = await depotService.getCellsList({
          order: "DESC",
          orderby: "time_created"
        });
      } catch (e) {
        console.log(e)
      }
    }
  },

  components: {
    NestedTree,
    NestedView
  },
  mixins: [user, notifications, loader],
  computed: {
    ...mapGetters('pageLoadTime', {
      startTime: getterTypes.GET_START_TIME,
    }),
    getSecotorsByDeport() {
      if (!this.chosen_deport) {
        return this.allSectors;
      }
      return [...this.allSectors].filter(sector => sector.uuid_deport === this.chosen_deport);
    },
    getChildren(arr) {
      return arr;
    }
  }
};
</script>

<style lang="scss">
.search-field {
  &.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border-color: #e0e0e0 !important;
  }
}
.divider-line {
  // margin-left: -11px;
}
.nested_data__aside {
  width: 100%;
  display: block;
  height: 80vh;
  overflow-y: scroll !important;
}
.nested-data {
  &__wrapper {
    height: inherit;
    height: 88vh;
    // overflow: scroll;
    // overflow: hidden;
    padding: 10px 0;
    pointer-events: auto;
    position: relative;
    display: block;
  }
  &__content {
    height: 100%;
  }
  &__aside {
  }
  &__left-side {
    overflow: scroll;
    height: 100%;
    &::-webkit-scrollbar {
      background-color: unset !important;
    }
  }
}
</style>
